import React from 'react';
import { Form, Input } from 'wally-design-system';
import { useModal, useNotification } from '~/context';
import { Form as RemixForm,  useActionData, useTransition, useFetcher } from 'remix';

export const PersonalPageForm: React.FunctionComponent = () => {
  const { openNotification } = useNotification()
  const { closeModal } = useModal()
  const fetcher = useFetcher();
  const verify = useFetcher();
  const [ payload, setPayload ] = React.useState({ state: 'input', code: '' })

  React.useEffect(() => {
    // if(!!payload.code && verify.type === 'init') {
    //   verify.submit({ code: payload.code },
    //     { method: "post", action: `/settings/accounts/verify_email` }
    //   );
    // }

    if (fetcher.type === 'done') {
      if (!!fetcher.data) {
        setPayload({ ...payload, state: 'success'})
        setTimeout(() => {
          closeModal();
        }, 1000)
      } else {
        setPayload({ ...payload, state: 'error'})
      }
    }
  }, [fetcher, payload.code]);

  const validateCode = (code: string) => {
    setPayload({ ...payload, code})
  }

  const checkUsername = async (pagename: any) => {
    // if (fetcher.type === "init") {
    verify.load(`/validations/pagename/${pagename}`);
    // }
    return verify.data
    // return await (await fetch(`/validations/pagename/${pagename}`)).json();
  }

  React.useEffect(() => {
    if (fetcher.type === 'done') {
      closeModal();
      const { status, message } = fetcher.data
      openNotification({ title: message, status });
    }
  }, [fetcher])

  // fetcher.data; // the data from the loader

  return (
    <>
      <Input type="rci" validation={{fn: validateCode, state: payload.state }}  id="code" maxLength={6} />
      <Form
        type="fetcher"
        component={fetcher.Form}
        fields={[
          { field: {
            type: 'text',
            id: 'pagename',
            label: 'Username',
            placeholder: '@pagename',
            required: true,
            validation: {
              fn: checkUsername,
              state: verify.state
            },
          }},
        ]}
        action='/profile/new'
        button={{ text: 'Save', variant: 'primary' }}
      />
    </>
  );
};
