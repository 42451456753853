import React from 'react';
import { Button } from 'wally-design-system';
import { useModal } from '~/context';

type ConfirmactionProps = {
  data: {
    to?: string;
  };
};
export const ConfirmAction: React.FC<ConfirmactionProps> = ({ data }) => {
  const { openModal } = useModal();
  console.log(data)
  return (
    <div className="flex flex-col space-y-4">
      <div className="text-subheading-bold text-center text-neutral-800">Choose your login option</div>
      <div className="flex space-x-4">
        <Button
          text="Wallet"
          variant="secondary"
          icon="wallet"
          rounded='full'
          onClick={() =>
            openModal({
              type: 'import_wallet',
              showBar: false,
              padding: 'large',
            })
          }
          customCss="w-full"
        />
        <Button
          text="Email"
          variant="primary"
          icon="arroba"
          rounded="full"
          onClick={() =>
            openModal({
              type: 'connect',
              showBar: false,
              padding: 'large',
            })
          }
          customCss="w-full"
        />
      </div>
      <span className="text-caption-medium text-center text-neutral-800">By continuing you accept our <span className="text-caption-bold-underlined text-primary-400">terms of conditions.</span></span>
    </div>
  );
};
