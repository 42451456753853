import React from 'react';
import { Form } from 'wally-design-system';
import { Form as RemixForm,  useActionData, useTransition, } from 'remix';

type ExperienceProps = {
  data?: any;
  text?: string;
};

export const ExperienceForm: React.FunctionComponent<ExperienceProps> = ({ data, text = 'Save' }: any) => {
  const { experience, wallets } = data || {};
  const options = wallets.map((wallet: any) => { return { name: wallet.public_key, value: wallet.id} })

  // const getTrustlines: any = ({ name }: any) => {
  //   if (name === 'GAKQMW5DOXE2IJSWL7UQYNI2NWCSRJI3M7XSZNGGIONXDBHGVWNGT4BT') {
  //     return [{ name: 'Option A', id: 1 },{ name: 'Option B', id: 2 }]
  //   } else {
  //     return [{ name: 'Option C', id: 3 },{ name: 'Option D', id: 4 }]
  //   }
  // }
  const getBalances: any = async (e: any) => {
    console.log('e', e)
    const req: any = await (await fetch(`https://horizon-testnet.stellar.org/accounts/${e.field.name}`)).json();
    return req.balances.map(({asset_code, asset_type, asset_issuer}: any) => {
      if (asset_type === 'native') {
        return { name: 'XLM', value: 'native' }
      } else if (!!asset_code) {
        return { name: asset_code, value: `${asset_code},${asset_issuer}`}
      }
    }).reverse()
  }

  console.log('experience',experience)

  return (
    <>
      <Form
        type={ !!experience ? 'default' : 'stepper'}
        component={RemixForm}
        fields={[
          { field: { type: 'text', id: 'id', hidden: true, defaultValue: experience?.id, } },
          { 
            field: {
              type: 'select',
              id: 'wallets_id',
              placeholder: 'Wallet',
              label: 'Wallet',
              required: true,
              defaultValue: experience?.wallets_id,
              options: options
            },
            title: 'Choose an account',
            description: 'Associate an account to your experience to receive payments.',
          },
          {
            field: {
              type: 'select',
              id: 'currency',
              placeholder: 'Assets',
              label: 'Currency',
              required: true,
              belongs_to: 'wallets_id',
              populateOptions: getBalances
            },
            title: 'Choose an Asset',
            description: 'Select an asset from your selected account.',
          },
            {
              field: {
              type: 'text',
              id: 'name',
              label: 'Name',
              placeholder: 'Name',
              required: true,
              defaultValue: experience?.name,
            },
            title: 'Experience Info',
            description: 'How would you like to name it?',
          },
          {
            field: {
              type: 'textarea',
              id: 'description',
              placeholder: 'Description',
              label: 'Description',
              required: true,
              defaultValue: experience?.description,
            },
            title: 'Experience Info',
            description: 'Describe in a few words whats about.',
          },
          { field: {
            type: 'time',
            id: 'duration',
            placeholder: 'Duration',
            label: 'Duration',
            icon: 'clock',
            required: true,
            defaultValue: experience?.duration,
          },
          title: 'Experience Info',
          description: 'Choose the duration of your experience',
        },
          { field: {
            type: 'number',
            id: 'price',
            placeholder: 'Price',
            label: 'Price',
            required: true,
            defaultValue: experience?.price,
          },
          title: 'Experience Info',
          description: 'Price for your experience for the given duration.',
        },
          // { field: {
          //   type: 'select',
          //   id: 'contracts_id',
          //   label: 'Smart Contracts',
          //   placeholder: 'Smart Contracts',
          //   required: true,
          //   defaultValue: '',
          //   options: [{name: 'XDR Direct Payment', id: '7e524b86-418a-4459-9fb7-ef85de7279b4'}]
          // }},
        ]}
        debug={false}
        action={ !!experience ? "/experiences/update" :"/experiences/new"}
        button={{ text, variant: 'primary' }}
      />
    </>
  );
};
