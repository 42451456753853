import React from 'react';
import { Form } from 'wally-design-system';
import { useModal } from '~/context/Modal';
import { useNotification } from '~/context/Notification';
import { useFetcher } from 'remix';

type LoginEmailFormType = {
  title: string;
  description: string;
  text: string;
}

export const LoginEmailForm: React.FunctionComponent<LoginEmailFormType> = ({text, title, description }) => {
  const { closeModal } = useModal()
  const { openNotification } = useNotification()
  const fetcher = useFetcher();

  React.useEffect(() => {
    if (fetcher.type === 'done') {
      const { message, status } = fetcher.data
      closeModal();
      openNotification({title: message, status });
    }
  }, [fetcher])

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="text-subheading-bold text-neutral-800">{title}</div>
        <div className="text-paragraph-medium-medium text-neutral-800">{description}</div>
      </div>
      <Form
        type="fetcher"
        component={fetcher.Form}
        fields={[
          {
            field: {
            type: 'email',
            id: 'email',
            label: '',
            placeholder: 'myCoolEmailAdress@example.com',
            required: true,
          }},
        ]}
        action='/auth/email'
        button={{ text, variant: 'primary' }}
      />
    </div>
  );
};
