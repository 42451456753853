import React from 'react';
import { Form } from 'wally-design-system';
import { useModal, useNotification } from '~/context';
import { useFetcher, Link, useNavigate } from 'remix';

type AvailabilityFormProps = {
  availability: {
    id?: string;
    timestamp?: string;
    experiences: [];
    experiences_id?: string;
    recurrennce: []
  };
};

export const AvailabilityForm: React.FunctionComponent<AvailabilityFormProps> =
  ({ availability = { id: null, timestamp: +new Date(), experiences: [] } }) => {
    const { closeModal, afterClose } = useModal();
    const { openNotification } = useNotification();
    const fetcher = useFetcher();
    const [ loading, setLoader] = React.useState({ status: false, text: 'Create' })

    const { id, timestamp, experiences } = availability;
    const [payload, loadPayload] = React.useState({
      id: id,
      experiences: experiences,
      experiences_id: '',
      timestamp: timestamp,
      time: ''
    });

    React.useEffect(() => {
      if (fetcher.type === 'actionSubmission') {
        setLoader({ status: true, text: 'Creating' });
      }
      if (fetcher.type === 'done') {
        const { status, message } = fetcher.data
        closeModal();
        openNotification({ title: message, status });
      }
    }, [fetcher])

    const expOptions = experiences.map(({id: value, name}: any) => {
      return { value, name }
    })
    const calculateTimes = () => {
      let arr = [];
      for (let i = 0; i <= 85500; ) {
        arr.push({
          value: `${i}`,
          name: `${new Date(i * 1000).toISOString().substr(11, 5)}`
        });
        i = i + 900;
      }
      return arr;
    };
    const times = calculateTimes();
    
    let navigate = useNavigate()

    const goToExperiences = () => {
      navigate("/experiences")
      closeModal()
    }
    return (
      <>
        {experiences.length === 0 ? 
        <div className="flex flex-col space-y-[20px]">
          <div className="text-neutral-800 text-subheading-bold">Experience required</div>
          <div>
            <span className="text-pragraph-medium text-neutral-800">You need to create an experience first. Go to the <div onClick={() => goToExperiences()} className="cursor-pointer underline text-primary-400">experience page </div></span>
          </div>
        </div>
        : (
           <Form
            type="fetcher"
            component={fetcher.Form}
            fields={[
              {field: {
                type: 'select',
                id: 'experiences_id',
                label: 'My Experiences',
                placeholder: 'experiences_id',
                defaultValue: payload.experiences_id,
                required: true,
                options: expOptions
              }},
              { field: {
                type: 'datepicker',
                id: 'timestamp',
                label: 'Date',
                icon: 'calendar',
                defaultValue: !!payload.timestamp ? payload.timestamp : +new Date(),
                required: true
              }},
              {field: {
                type: 'select',
                id: 'time',
                label: 'Set Time',
                placeholder: 'time',
                defaultValue: payload.time,
                required: true,
                options: times
              }},
              { field: {
                type: 'checkbox',
                id: 'recurrence',
                label: 'Set Recurrence',
                defaultValue: payload.time,
                required: false,
                options: [
                  { name: 'SUN', value: '0' },
                  { name: 'MON', value: '1' },
                  { name: 'TUE', value: '2' },
                  { name: 'WEN', value: '3' },
                  { name: 'THU', value: '4' },
                  { name: 'FRI', value: '5' },
                  { name: 'SAT', value: '6' }
                ]
              }}
            ]}
            debug={false}
            action="/agenda/availabilities/new"
            button={{ text: loading.text, variant: 'primary', loading: loading.status }}
         />
        )}
      </>
    );
  };
