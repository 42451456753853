
import React from 'react';
import { Form as RemixForm,  useActionData, useTransition, useFetcher } from 'remix';

type ReleaseFundsProps = { order: any };

export const ReleaseFunds: React.FC<ReleaseFundsProps> = ({ order }) => {
  const fetcher = useFetcher();

  React.useEffect(() => {
    console.log('fetcher.data', fetcher)
    if (fetcher.type === "init") {
      fetcher.load(`/orders/escrow/${order.orders_id}`);
    }
  }, [fetcher]);

  console.log('fetcher.data', fetcher.data)
  return (
    <>
     <div className="text-neutral-800"> Sign in Release</div>
    </>
  );
};
