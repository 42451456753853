import React from 'react';
import { Form, Input } from 'wally-design-system';
import { useModal } from '~/context/Modal';
import { useNotification } from '~/context/Notification';
import { useFetcher } from 'remix';

export const VerifyEmailForm: React.FunctionComponent = () => {  const fetcher = useFetcher();
  const verify = useFetcher();
  const [ payload, setPayload ] = React.useState({ state: 'input', code: '', email: '' })



  React.useEffect(() => {
    if (fetcher.type === 'done') {
      const { message, status, data } = fetcher.data
      setPayload({ ...payload, email: data.email} )
    }
  }, [fetcher])

  React.useEffect(() => {
    if(!!payload.code && verify.type === 'init') {
      verify.submit({ code: payload.code, email: payload.email },
        { method: "post", action: `/settings/accounts/verify_email` }
      );
    }
    if (verify.type === 'done') {
      console.log('verify...', verify.data)
      if (!!verify.data) {
        setPayload({ ...payload, state: 'success'})
      } else {
        setPayload({ ...payload, state: 'error'})
      }
    }
  }, [verify, payload.code]);

  const validateCode = (code: string) => {
    setPayload({ ...payload, code})
  }
  // const assertText = (state: string) => {
  //   switch(state)
  // }
  const text_email = {title:"Start process", description:"We will be sending a 6 digit code to the following address:"}
  const text_rci = {title:"Code sent succcessfully", description:`You will be receiving a 6 digit code at ${payload.email}, please check mail.`}

  return (
    <div className="flex flex-col space-y-4">
      <div>
        { !payload.email ?
          <>
            <div className="text-subheading-bold text-neutral-800">{text_email.title}</div>
            <div className="text-paragraph-medium-medium text-neutral-800">{text_email.description}</div>
          </>
          :
          <>
            <div className="text-subheading-bold text-neutral-800">{text_rci.title}</div>
            <div className="text-paragraph-medium-medium text-neutral-800">{text_rci.description}</div>
          </>
        }
      </div>
      {
        !!payload.email ?
          <Input type="rci" validation={{fn: validateCode, state: payload.state }}  id="code" maxLength={6} />
          :
          <Form
          type="fetcher"
          component={fetcher.Form}
          fields={[
            { field: {
              type: 'email',
              id: 'email',
              label: '',
              placeholder: 'myCoolEmailAdress@example.com',
              required: true,
            }},
          ]}
          action='/settings/accounts/add_user'
          button={{ text: 'Send', variant: 'primary' }}
        />
      }
      {
        payload.state === 'success' && 
        <div className="text-neutral-800">Your email has been verified!</div>
      }
    </div>
  );
};
