import React from 'react';
import { Form } from 'wally-design-system';
import { useModal } from '~/context/Modal';
import { useNotification } from '~/context/Notification';
import { useFetcher } from 'remix';
import { languages } from '~/utils/languages';
type UserProps = {
  user: any;
};

export const EditUserForm: React.FunctionComponent<UserProps> = ({
  user,
}: any) => {
  const { closeModal } = useModal()
  const [ loading, setLoader] = React.useState({ status: false, text: 'Save' })
  const { openNotification } = useNotification()
  const fetcher = useFetcher();

  React.useEffect(() => {
    console.log('fetcher.type', fetcher.type)
    if (fetcher.type === 'actionSubmission') {
      setLoader({ status: true, text: 'Saving' });
    }
    if (fetcher.type === 'done') {
      // closeModal()
      closeModal();
      const { status, message } = fetcher.data
      openNotification({ title: message, status });
      console.log('DONE')
    }
  }, [fetcher])

  return (
    <>
      <Form
        type="fetcher"
        component={fetcher.Form}
        fields={[
          { field: {
            type: 'email',
            id: 'email',
            label: 'Email',
            disabled: true,
            placeholder: 'youremail@example.com',
            required: true,
            defaultValue: user.email,
          }},
          { field: {
            type: 'text',
            id: 'first_name',
            label: 'First Name',
            placeholder: 'First Name',
            required: true,
            defaultValue: user.first_name,
          }},
          { field: {
            type: 'text',
            id: 'last_name',
            label: 'Last Name',
            placeholder: 'Last Name',
            required: true,
            defaultValue: user.last_name,
          }},
          { field: {
            type: 'select',
            id: 'languages',
            label: 'Languages',
            placeholder: 'Languages',
            options: languages,
            required: true,
            defaultValue: !!user.languages ? user.languages : 'en',
          }},
        ]}
        debug={false}
        action="/settings/user"
        button={{ text: loading.text, variant: 'positive', loading: loading.status }}
      />
    </>
  );
};
