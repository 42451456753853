import React from 'react';
import { RadioGroup, Button, Loader } from 'wally-design-system';
import { useModal } from '~/context';
import { isConnected } from '@stellar/freighter-api';
// import albedo from '@albedo-link/intent';
import { useFetcher } from 'remix';
// import { isBrowser } from '~/utils/misc.client';
import { WalletClient } from '~/actions/WalletClient';

const Albedo = ({ connectWallet }: any) => {
  const wc = new WalletClient('albedo', 'TESTNET')
  wc.getPublicKey().then(async (account: any) => { await connectWallet({ public_key: account.pubkey, wallet_preference: 'albedo' }) })
  return <Loader />;
};

const Freighter = ({ connectWallet }: any) => {
  React.useEffect(() => {
    if (isConnected()) {
      const wc = new WalletClient('freighter', 'TESTNET')
      wc.getPublicKey().then( async (value: any) => {
        const public_key = await value()
        await connectWallet({ public_key, wallet_preference: 'freighter' })  
      })
    }
  }, []);
  return <Loader />;
};

const Rabet = ({ connectWallet }: any) => {
  const wc = new WalletClient('rabet', 'TESTNET')
  wc.getPublicKey().then(async ({ publicKey }: any) => await connectWallet({ public_key: publicKey, wallet_preference: 'rabet' }))
  return <Loader />;
};

const options = [
  {
    name: 'Albedo',
    icon: 'albedo',
  },
  {
    name: 'Rabet',
    icon: 'rabet',
  },
  {
    name: 'Freighter',
    icon: 'freighter',
  },
];

type ImportAccountProps = {};

const walletAssert = (view: any, connectWallet: any) => {
  switch (view) {
    case 'Rabet':
      return <Rabet connectWallet={connectWallet} />;
    case 'Freighter':
      return <Freighter connectWallet={connectWallet} />;
    case 'Albedo':
      return <Albedo connectWallet={connectWallet} />;
    default:
      return <></>;
  }
};

export const ImportAccount: React.FC<ImportAccountProps> = ({}) => {
  const [view, setView] = React.useState('');
  const [selected, Select] = React.useState(options[0]);
  const [payload, setPayload] = React.useState({ public_key: '', wallet_preference: ''});
  const fetcher = useFetcher();
  const { closeModal } = useModal();

  React.useEffect(() => {
    const { public_key, wallet_preference } = payload;
    if (public_key && fetcher.type === 'init') {
      fetcher.submit(
        { public_key, wallet_preference },
        { method: 'post', action: '/auth_wallet' }
      );
    }
    if (fetcher.type === 'done') {
      console.log('closing window ...', fetcher.data)
      closeModal();
    }
  }, [payload.public_key, fetcher]);

  const button = {
    text: `${view === '' ? `Continue with ${selected.name}` : 'Cancel'}`,
    variant: `${view === '' ? 'primary' : 'warning'}`,
  };

  return (
    <div className="flex flex-col space-y-4">
      {payload.public_key === '' ? (
        <>
          <div>
            <div className="text-subheading-bold text-neutral-800">Wallets</div>
            <div className="text-paragraph-medium-medium text-neutral-800">Choose your favorite wallet to connect with.</div>
          </div>
          <div>
            {view === '' ? (
              <RadioGroup options={options} setValue={Select} />
            ) : (
              walletAssert(view, setPayload)
            )}
            <Button
              text={button.text}
              variant={button.variant}
              onClick={() => setView(view === '' ? selected.name : '')}
              customCss="w-full"
            />
          </div>
          <div className="text-center text-caption-medium text-neutral-600">We use stellar base wallets, learn more about creating your first stellar account.</div>
        </>
      ) : 
        <>
          <div className="text-center">
            <div className="text-subheading-bold text-neutral-800">Connecting ...</div>
            <div className="text-paragraph-medium-medium text-neutral-800">You will be redirected soon.</div>
          </div>
        </>
      }
    </div>
  );
};
