export const languages = [
  {name: 'Afrikaans', fullname:"Afrikaans", value:"af"},
  {name: 'Albanian', fullname:"Albanian - shqip", value:"sq"},
  {name: 'Amharic', fullname:"Amharic - አማርኛ", value:"am"},
  {name: 'Arabic', fullname:"Arabic - العربية", value:"ar"},
  {name: 'Aragonese', fullname:"Aragonese - aragonés", value:"an"},
  {name: 'Armenian', fullname:"Armenian - հայերեն", value:"hy"},
  {name: 'Asturian', fullname:"Asturian - asturianu", value:"ast"},
  {name: 'Azerbaijani', fullname:"Azerbaijani - azərbaycan dili", value:"az"},
  {name: 'Basque', fullname:"Basque - euskara", value:"eu"},
  {name: 'Belarusian', fullname:"Belarusian - беларуская", value:"be"},
  {name: 'Bengali', fullname:"Bengali - বাংলা", value:"bn"},
  {name: 'Bosnian', fullname:"Bosnian - bosanski", value:"bs"},
  {name: 'Breton', fullname:"Breton - brezhoneg", value:"br"},
  {name: 'Bulgarian', fullname:"Bulgarian - български", value:"bg"},
  {name: 'Catalan', fullname:"Catalan - català", value:"ca"},
  {name: 'Central Kurdish', fullname:"Central Kurdish - کوردی (دەستنوسی عەرەبی)", value:"ckb"},
  {name: 'Chinese', fullname:"Chinese - 中文", value:"zh"},
  {name: 'Chinese（香港)', fullname:"Chinese (Hong Kong) - 中文（香港）", value:"zh-HK"},
  {name: 'Chinese（简体)', fullname:"Chinese (Simplified) - 中文（简体）", value:"zh-CN"},
  {name: 'Chinese（繁體)', fullname:"Chinese (Traditional) - 中文（繁體）", value:"zh-TW"},
  {name: 'Corsican', fullname:"Corsican", value:"co"},
  {name: 'Croatian', fullname:"Croatian - hrvatski", value:"hr"},
  {name: 'Czech', fullname:"Czech - čeština", value:"cs"},
  {name: 'Danish', fullname:"Danish - dansk", value:"da"},
  {name: 'Dutch', fullname:"Dutch - Nederlands", value:"nl"},
  {name: 'English', fullname:"English", value:"en"},
  {name: 'Esperanto', fullname:"Esperanto - esperanto", value:"eo"},
  {name: 'Estonian', fullname:"Estonian - eesti", value:"et"},
  {name: 'Faroese', fullname:"Faroese - føroyskt", value:"fo"},
  {name: 'Filipino', fullname:"Filipino", value:"fil"},
  {name: 'Finnish', fullname:"Finnish - suomi", value:"fi"},
  {name: 'French', fullname:"French - français", value:"fr"},
  {name: 'Galician', fullname:"Galician - galego", value:"gl"},
  {name: 'Georgian', fullname:"Georgian - ქართული", value:"ka"},
  {name: 'German', fullname:"German - Deutsch", value:"de"},
  {name: 'Greek', fullname:"Greek - Ελληνικά", value:"el"},
  {name: 'Guarani', fullname:"Guarani", value:"gn"},
  {name: 'Gujarati', fullname:"Gujarati - ગુજરાતી", value:"gu"},
  {name: 'Hausa', fullname:"Hausa", value:"ha"},
  {name: 'Hawaiian', fullname:"Hawaiian - ʻŌlelo Hawaiʻi", value:"haw"},
  {name: 'Hebrew', fullname:"Hebrew - עברית", value:"he"},
  {name: 'Hindi', fullname:"Hindi - हिन्दी", value:"hi"},
  {name: 'Hungarian', fullname:"Hungarian - magyar", value:"hu"},
  {name: 'Icelandic', fullname:"Icelandic - íslenska", value:"is"},
  {name: 'Indonesian', fullname:"Indonesian - Indonesia", value:"id"},
  {name: 'Interlingua', fullname:"Interlingua", value:"ia"},
  {name: 'Irish', fullname:"Irish - Gaeilge", value:"ga"},
  {name: 'Italian', fullname:"Italian - italiano", value:"it"},
  {name: 'Japanese', fullname:"Japanese - 日本語", value:"ja"},
  {name: 'Kannada', fullname:"Kannada - ಕನ್ನಡ", value:"kn"},
  {name: 'Kazakh', fullname:"Kazakh - қазақ тілі", value:"kk"},
  {name: 'Khmer', fullname:"Khmer - ខ្មែរ", value:"km"},
  {name: 'Korean', fullname:"Korean - 한국어", value:"ko"},
  {name: 'Kurdish', fullname:"Kurdish - Kurdî", value:"ku"},
  {name: 'Kyrgyz', fullname:"Kyrgyz - кыргызча", value:"ky"},
  {name: 'Lao', fullname:"Lao - ລາວ", value:"lo"},
  {name: 'Latin', fullname:"Latin", value:"la"},
  {name: 'Latvian', fullname:"Latvian - latviešu", value:"lv"},
  {name: 'Lingala', fullname:"Lingala - lingála", value:"ln"},
  {name: 'Lithuanian', fullname:"Lithuanian - lietuvių", value:"lt"},
  {name: 'Macedonian', fullname:"Macedonian - македонски", value:"mk"},
  {name: 'Malay', fullname:"Malay - Bahasa Melayu", value:"ms"},
  {name: 'Malayalam', fullname:"Malayalam - മലയാളം", value:"ml"},
  {name: 'Maltese', fullname:"Maltese - Malti", value:"mt"},
  {name: 'Marathi', fullname:"Marathi - मराठी", value:"mr"},
  {name: 'Mongolian', fullname:"Mongolian - монгол", value:"mn"},
  {name: 'Nepali', fullname:"Nepali - नेपाली", value:"ne"},
  {name: 'Norwegian', fullname:"Norwegian - norsk", value:"no"},
  {name: 'Norwegian Bokmål', fullname:"Norwegian Bokmål - norsk bokmål", value:"nb"},
  {name: 'Norwegian Nynorsk', fullname:"Norwegian Nynorsk - nynorsk", value:"nn"},
  {name: 'Occitan', fullname:"Occitan", value:"oc"},
  {name: 'Oriya', fullname:"Oriya - ଓଡ଼ିଆ", value:"or"},
  {name: 'Oromo', fullname:"Oromo - Oromoo", value:"om"},
  {name: 'Pashto', fullname:"Pashto - پښتو", value:"ps"},
  {name: 'Persian', fullname:"Persian - فارسی", value:"fa"},
  {name: 'Polish', fullname:"Polish - polski", value:"pl"},
  {name: 'Portuguese', fullname:"Portuguese - português", value:"pt"},
  {name: 'Punjabi', fullname:"Punjabi - ਪੰਜਾਬੀ", value:"pa"},
  {name: 'Quechua', fullname:"Quechua", value:"qu"},
  {name: 'Romanian', fullname:"Romanian - română", value:"ro"},
  {name: 'Romanian', fullname:"Romanian (Moldova) - română (Moldova)", value:"mo"},
  {name: 'Romansh', fullname:"Romansh - rumantsch", value:"rm"},
  {name: 'Russian', fullname:"Russian - русский", value:"ru"},
  {name: 'Scottish Gaelic', fullname:"Scottish Gaelic", value:"gd"},
  {name: 'Serbian', fullname:"Serbian - српски", value:"sr"},
  {name: 'Serbo', fullname:"Serbo - Croatian", value:"sh"},
  {name: 'Shona', fullname:"Shona - chiShona", value:"sn"},
  {name: 'Sindhi', fullname:"Sindhi", value:"sd"},
  {name: 'Sinhala', fullname:"Sinhala - සිංහල", value:"si"},
  {name: 'Slovak', fullname:"Slovak - slovenčina", value:"sk"},
  {name: 'Slovenian', fullname:"Slovenian - slovenščina", value:"sl"},
  {name: 'Somali', fullname:"Somali - Soomaali", value:"so"},
  {name: 'Southern Sotho', fullname:"Southern Sotho", value:"st"},
  {name: 'Spanish', fullname:"Spanish - español", value:"es"},
  {name: 'Sundanese', fullname:"Sundanese", value:"su"},
  {name: 'Swahili', fullname:"Swahili - Kiswahili", value:"sw"},
  {name: 'Swedish', fullname:"Swedish - svenska", value:"sv"},
  {name: 'Tajik', fullname:"Tajik - тоҷикӣ", value:"tg"},
  {name: 'Tamil', fullname:"Tamil - தமிழ்", value:"ta"},
  {name: 'Tatar', fullname:"Tatar", value:"tt"},
  {name: 'Telugu', fullname:"Telugu - తెలుగు", value:"te"},
  {name: 'Thai', fullname:"Thai - ไทย", value:"th"},
  {name: 'Tigrinya', fullname:"Tigrinya - ትግርኛ", value:"ti"},
  {name: 'Tongan', fullname:"Tongan - lea fakatonga", value:"to"},
  {name: 'Turkish', fullname:"Turkish - Türkçe", value:"tr"},
  {name: 'Turkmen', fullname:"Turkmen", value:"tk"},
  {name: 'Twi', fullname:"Twi", value:"tw"},
  {name: 'Ukrainian', fullname:"Ukrainian - українська", value:"uk"},
  {name: 'Urdu', fullname:"Urdu - اردو", value:"ur"},
  {name: 'Uyghur', fullname:"Uyghur", value:"ug"},
  {name: 'Uzbek', fullname:"Uzbek - o‘zbek", value:"uz"},
  {name: 'Vietname', fullname:"Vietname: '', fullnamese - Tiếng Việt", value:"vi"},
  {name: 'Walloon', fullname:"Walloon - wa", value:"wa"},
  {name: 'Welsh', fullname:"Welsh - Cymraeg", value:"cy"},
  {name: 'Western Frisian', fullname:"Western Frisian", value:"fy"},
  {name: 'Xhosa', fullname:"Xhosa", value:"xh"},
  {name: 'Yiddish', fullname:"Yiddish", value:"yi"},
  {name: 'Yoruba', fullname:"Yoruba - Èdè Yorùbá", value:"yo"},
  {name: 'Zulu', fullname:"Zulu - isiZulu", value:"zu"}
];