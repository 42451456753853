import React from 'react';
import { Checkout as CheckoutComponent, Button, OrderSummary, Form } from 'wally-design-system';
import albedo from '@albedo-link/intent';
import { useFetcher, redirect, Link } from 'remix';
import { useModal, useNotification } from '~/context';

type OrderPreviewProps = {
  order: {
    id: string;
    cover_url: string;
    public_key: string;
    availabilities_id: string;
    experiences_id: string;
    timestamp: string;
    name: string;
    date: string;
    time: string;
    price: string;
  };
};

export const OrderPreview: React.FC<OrderPreviewProps> = ({ order }) => {
  const { openModal, closeModal } = useModal();
  const { openNotification } = useNotification();
  // const [ payload, setPayload ] = React.useState({ start: false, sequence: ''})
  const fetcher = useFetcher();
  const wallets = useFetcher();
  const isClient = typeof window !== "undefined"
  const { availabilities_id, experiences_id, timestamp } = order
  const [ wallet_options, setWalletOptions ] = React.useState([])


  React.useEffect(() => {
    // if (!!isClient) {
    //   const w: any = window
    //   if (w.StellarSdk){
    //     var server = new w.StellarSdk.Server('https://horizon-testnet.stellar.org');
    //     server.loadAccount('GAKQMW5DOXE2IJSWL7UQYNI2NWCSRJI3M7XSZNGGIONXDBHGVWNGT4BT').then(function(account: any) { setPayload({...payload, sequence: account.sequence}) })
    //   }
    // }
    if (wallets.type === "init") {
      wallets.load(`/settings/wallets`);
    }
    
    if (fetcher.type === 'init') {
      // const sequence = payload.sequence
      const source = 'GAKQMW5DOXE2IJSWL7UQYNI2NWCSRJI3M7XSZNGGIONXDBHGVWNGT4BT'
      // fetcher.submit(
      //   { timestamp, experiences_id, availabilities_id, source},
      //   { method: 'post', action: '/orders/new' }
      // );
    }
    
    if (fetcher.type === 'actionSubmission') {
      console.log("fetcher.type === 'actionSubmission'")
    }
    if (fetcher.type === 'done') {
      console.log("fetcher.type === 'done'")
      if (!fetcher.data.success){
        openNotification({ title: fetcher.data.message, status: 401})
        closeModal()
      }
    }
  }, [fetcher]); 
  React.useEffect(() => {
    if (wallets.data) {
      const data = wallets.data.map((item: any) => {
        return { value: item.public_key, name: item.public_key }
      })
      setWalletOptions(data)
    }
  }, [wallets.data])
  const isSubmitting = fetcher.type === 'actionSubmission'
  return (
    <div className='flex flex-col space-y-4'>
      <OrderSummary order={order} />
      {/* <CheckoutComponent onClick={() => makePayment()} order={order} /> */}
      {/* <div className="text-neutral-800">{payload.sequence}</div> */}
      { wallet_options.length !== 0 &&
        <Form
          type="fetcher"
          component={fetcher.Form}
          fields={[
            { field: {
              type: 'select',
              id: 'source',
              label: 'Select one of your wallets',
              options: wallet_options,
              // variant: 'full',
              required: true,
            }},
            { field: {
              type: 'text',
              hidden: true,
              id: 'timestamp',
              defaultValue: timestamp,
            }},
            { field: {
              type: 'text',
              hidden: true,
              id: 'experiences_id',
              defaultValue: experiences_id,
            }},
            { field: {
              type: 'text',
              hidden: true,
              id: 'availabilities_id',
              defaultValue: availabilities_id,
            }},
          ]}
          debug={false}
          action='/orders/new'
          button={{ text: isSubmitting ? 'Creating' : 'Continue', variant: 'primary', loading: isSubmitting ? true : false }}
        />
      }
      {/* <Button onClick={() => setPayload({...payload, start: true})} loading={!payload.sequence || payload.start} text={payload.start ? "Creating" : "Continue"} customCss="w-full" /> */}
    </div>
  );
};
