import React from 'react';
import { Button, RadioGroup } from 'wally-design-system';
import { useModal } from '~/context';
import { PersonalPageForm } from '~/forms';
type PageCreationProps = {

};

const options = [
  {
    name: 'Personal',
    image_url: 'https://imagedelivery.net/uDbEDRBQqhBXrrfuCRrATQ/ec26d342-d36c-470a-96ff-b8f7d3ebaa00/public',
    description: '',
    disabled: false
  },
  {
    name: 'Team',
    image_url: 'https://imagedelivery.net/uDbEDRBQqhBXrrfuCRrATQ/139d17f4-e8cb-46b8-4a2b-b03df035f700/public',
    description: '',
    badge: 'SOON',
    disabled: true
  },
  {
    name: 'Organization',
    image_url: 'https://imagedelivery.net/uDbEDRBQqhBXrrfuCRrATQ/56790284-b48d-47d1-414a-f3bdd56f6a00/public',
    description: '',
    badge: 'SOON',
    disabled: true
  }
];


const Personal = ({ }: any) => {

  return <>
    <div className="text-h3-normal-bold text-neutral-800">Personal Pages</div>
    <div className="text-paragraph-medium text-neutral-800">To continue you must provide an invitation code</div>
    <PersonalPageForm />
    <div className="text-paragraph-medium text-neutral-800">Want to get access to our closed beta?</div>
  </>
};

const pageAssert = (view: any) => {
  switch (view) {
    case 'Personal':
      return <Personal />;
    default:
      return <></>;
  }
};

export const PageCreation: React.FC<PageCreationProps> = ({}) => {
  const { openModal } = useModal();
  const [ view, setView] = React.useState('')
  const [selected, Select] = React.useState(options[0]);

  return (
    <div className="flex flex-col space-y-[24px]">
      <div>
      {view === '' ? (
        <>
        <div className="text-h3-normal-bold text-neutral-800 mb-[8px]">Choose a Page</div>
          <div className="flex flex-col space-y-[24px]">
            <RadioGroup options={options} setValue={Select} variant="vertical" />
            <Button
              text="Continue"
              variant="primary"
              onClick={() => setView(view === '' ? selected.name : '')}
              customCss="w-full"
            />
          </div>
        </>
      ) : (
        <>
          {pageAssert(view)}
        </>
      )} 
      </div>
      <span className="text-caption-medium text-center text-neutral-800">By continuing you accept our <span className="text-caption-bold-underlined text-primary-400">terms of conditions.</span></span>
    </div>
  );
};
