import React from 'react';
import { Form as RemixForm,  useActionData, useTransition, useFetcher } from 'remix';
import albedo from '@albedo-link/intent';
import { useModal, useNotification } from '~/context';
import { Button } from 'wally-design-system';
import { WalletClient } from '~/actions/WalletClient';

type SignReleaseProps = { order: { orders_id: string, destination: string } };

export const SignRelease: React.FC<SignReleaseProps> = ({ order }) => {
  const { openModal, closeModal } = useModal();
  const { openNotification } = useNotification();
  const fetcher = useFetcher();
  const payload = useFetcher();
  
  React.useEffect(() => {
    if (fetcher.type === "init") {
      fetcher.load(`/orders/escrow/${order.orders_id}`);
    }
  }, [fetcher]);

  React.useEffect(() => {
    if (payload.type === 'done') {
      const { message: title, status } = payload.data
      closeModal()
      openNotification({ title, status })
    }
  }, [payload])

  const makePayment = async ({ xdr, orders_id, wallet_preference }: any) => {
    console.log(xdr, orders_id, wallet_preference)
    const wc = new WalletClient(wallet_preference, 'TESTNET')
    // const { signed_envelope_xdr: signedTx } = await wc.signTransaction(xdr, false)
    const { signed_envelope_xdr }: any = await wc.signTransaction(xdr, false)
    console.log('signed_envelope_xdr', signed_envelope_xdr)
    // console.log('signedTx', signedTx)
      if (!!signed_envelope_xdr && payload.type === 'init') {
      payload.submit(
        { signed_envelope_xdr, orders_id },
        { method: 'post', action: '/orders/escrow/sign_release' }
      );
      }
    // openModal({
    //   type: 'payment_success',
    //   content: `testnet/tx/${res.tx_hash}`
    // });
    // albedo.tx({
    //   xdr: xdr,
    //   network: 'testnet',
    //   submit: false
    //   })
    //   .then((res: any) => {
    //     console.log('RES', res)
    //     // if (res.signed_envelope_xdr && payload.type === 'init') {
    //     //   const { signed_envelope_xdr, xdr } = res
    //     //   payload.submit(
    //     //     { signed_envelope_xdr, xdr, orders_id },
    //     //     { method: 'post', action: '/orders/escrow/sign_release' }
    //     //   );
    //     //   // setPayload({ ...res.horizonResult })
    //     // }
    //     // openModal({
    //     //   type: 'payment_success',
    //     //   content: `testnet/tx/${res.tx_hash}`
    //     // });
    //   })
    //   .catch((e: any) => console.error(e));
  };

  return (
    <div className="flex flex-col space-y-[24px]">
     <div className="text-paragraph-large-bold  text-neutral-800">Sign Release</div>
     <div>
      <div className="flex flex-col space-y-[8px]">
      <div className="text-paragraph-medium-medium text-neutral-500">
        You are about to release the funds
      </div>
      <div>
        <div className="text-caption-bold text-primary-500 uppercase">
          Destination
        </div>
        <div className="text-paragraph-medium-bold text-neutral-600 max-w-[256px] truncate">
          {order.destination}
        </div>
      </div>
      </div>
     </div>
     <Button onClick={() => makePayment(fetcher.data)} text="Continue" />
    </div>
  );
};
