import React from 'react';
import { Form } from 'wally-design-system';
import { useModal } from '~/context/Modal';
import { useNotification } from '~/context/Notification';
import { useFetcher } from 'remix';

type ReviewFormType = {
  appointment: any;
}

export const ReviewForm: React.FunctionComponent<ReviewFormType> = ({ appointment }) => {
  const { closeModal } = useModal()
  const { openNotification } = useNotification()
  const fetcher = useFetcher();

  console.log('appointment', appointment)
  React.useEffect(() => {
    if (fetcher.type === 'done') {
      const { message, status } = fetcher.data
      closeModal();
      openNotification({title: message, status });
    }
  }, [fetcher])

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="text-subheading-bold text-neutral-800">Review</div>
        <div className="text-paragraph-medium-medium text-neutral-800"></div>
      </div>
      <Form
        type="fetcher"
        component={fetcher.Form}
        fields={[
          {
            field: {
            type: 'text',
            id: 'appointments_id',
            defaultValue: appointment.id,
            hidden: true,
            required: true,
          }},
          {
            field: {
            type: 'text',
            id: 'comment',
            label: 'Comment',
            placeholder: 'write...',
            required: true,
          }},
        ]}
        action='/appointments/review'
        button={{ text: 'Save', variant: 'primary' }}
      />
    </div>
  );
};
