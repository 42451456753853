import React from 'react';
import { useModal } from '~/context';
import { Button, EventSummary } from 'wally-design-system';

type EventProps = {
  event: any;
};

export const Event: React.FC<EventProps> = ({ event }) => {
  const { openModal, closeModal } = useModal();

  return (
    <EventSummary
      CTA={<Button text="Edit" onClick={() => openModal({ type: 'event_settings', content: event, padding: 'large' })} />}
      event={event}
    />
  );
};
