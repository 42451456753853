import React from 'react';
import { Form } from 'wally-design-system';
import { Form as RemixForm,  useActionData, useTransition, useFetcher } from 'remix';

type ProfileProps = {
  profile?: any;
  action: '/profile/new' | '/profile/update'
};

export const ProfileForm: React.FunctionComponent<ProfileProps> = ({
  profile, action
}: any) => {
  const fetcher = useFetcher();

  const checkUsername = async (pagename: any) => {
    // if (fetcher.type === "init") {
    fetcher.load(`/validations/pagename/${pagename}`);
    // }
    if (profile?.pagename === pagename)return true;
    return fetcher.data
    // return await (await fetch(`/validations/pagename/${pagename}`)).json();
  }

  // React.useEffect(() => {
  //   console.log('fetcher.data', fetcher)
  //   // if (fetcher.type === "init") {
  //   //   fetcher.load("/some/route");
  //   // }
  // }, [fetcher]);
  console.log('fetcher.data', fetcher)

  // fetcher.data; // the data from the loader

  return (
    <>
      <Form
        component={RemixForm}
        // type='fetcher'
        fields={[
          { field: {
            type: 'text',
            id: 'pagename',
            label: 'Username',
            placeholder: '@pagename',
            required: true,
            validation: {
              fn: checkUsername,
              state: fetcher.state
            },
            defaultValue: profile?.pagename,
          }},
        ]}
        action={action}
        button={{ text: 'Save', variant: 'primary' }}
      />
    </>
  );
};
