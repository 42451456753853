import React, { FunctionComponent } from 'react';
import { useTheme } from './Theme';
import { LoginEmailForm, ExperienceForm, AvailabilityForm, ProfileForm, VerifyEmailForm, ReviewForm } from '~/forms';
import { ConnectWith, ConfirmAction, PageCreation, SignRelease, ReleaseFunds } from '~/components';
// import { SignUp, SignIn, ExperienceForm, AvailabilityForm } from '@/forms';
import { Event, EventSettings, ImportAccount, OrderPreview } from '~/sections';
import { PaymentSuccess } from 'wally-design-system';
import { Modal as ModalComponent } from 'wally-design-system';
import { EditUserForm } from '~/forms/EditUserForm';

type ModalContextType = {
  isOpen: boolean;
  openModal: (action: {
    type: string;
    content?: any;
    padding?: any;
    size?: any;
    showBar?: boolean;
    onClose?: () => void;
  }) => void;
  afterClose: () => void;
  closeModal: () => void;
};

enum ModalTypeE {
  CONNECT = 'connect',
  SIGN_IN = 'sign_in',
  CONNECT_WITH = 'connect_with',
  ORDER_PREVIEW = 'order_preview',
  SIGN_RELEASE = 'sign_release',
  RELEASE_FUNDS = 'release_funds',
  PAGE_CREATION = 'page_creation',
  EDIT_USER = 'edit_user',
  IMPORT_WALLET = 'import_wallet',
  VERIFY_EMAIL_ACCOUNT = 'verify_email_account',
  EVENT = 'event',
  EVENT_SETTINGS = 'event_settings',
  NEW_EXPERIENCE = 'new_experience',
  EDIT_EXPERIENCE = 'edit_experience',
  NEW_PROFILE = 'new_profile',
  EDIT_PROFILE = 'edit_profile',
  NEW_AVAILABILITY = 'new_availability',
  EXPERIENCE_OVERVIEW = 'experience_overview',
  PAYMENT_SUCCESS = 'payment_success',
  CONFIRM_ACTION = 'confirm_action',
  REVIEW_FORM = 'review_form'
}

const modalAssert = (action: { type: string; content: any }) => {
  switch (action.type) {
    case ModalTypeE.CONNECT:
      return <LoginEmailForm text="Send" title="Connect with your Email" description="We will be sending a magic link to the following address:" />;
    case ModalTypeE.CONNECT_WITH:
      return <ConnectWith />;
    case ModalTypeE.IMPORT_WALLET:
      return <ImportAccount />;
    case ModalTypeE.VERIFY_EMAIL_ACCOUNT:
      return <VerifyEmailForm />;
    case ModalTypeE.EDIT_USER:
      return <EditUserForm user={action.content}/>;
    case ModalTypeE.NEW_EXPERIENCE:
      return <ExperienceForm text="Confirm" data={action.content}/>;
    case ModalTypeE.EDIT_EXPERIENCE:
      return <ExperienceForm data={action.content} text="Save" />;
    case ModalTypeE.NEW_PROFILE:
      return <ProfileForm action='/profile/new' />;
    case ModalTypeE.EDIT_PROFILE:
      return <ProfileForm profile={action.content} action='/profile/update' />;
    case ModalTypeE.NEW_AVAILABILITY:
      return <AvailabilityForm availability={action.content} />;
    case ModalTypeE.ORDER_PREVIEW:
      return <OrderPreview order={action.content} />;
    case ModalTypeE.PAYMENT_SUCCESS:
      return <PaymentSuccess tx_hash={action.content} />;
    case ModalTypeE.CONFIRM_ACTION:
      return <ConfirmAction data={action.content} />;
    case ModalTypeE.EVENT:
      return <Event event={action.content} />;
    case ModalTypeE.EVENT_SETTINGS:
      return <EventSettings event={action.content} />;
    case ModalTypeE.PAGE_CREATION:
      return <PageCreation />;
    case ModalTypeE.REVIEW_FORM:
    return <ReviewForm appointment={action.content}/>;
    case ModalTypeE.SIGN_RELEASE:
      return <SignRelease order={action.content}/>;
    case ModalTypeE.RELEASE_FUNDS:
      return <ReleaseFunds order={action.content}/>;
    // case ModalTypeE.EDIT_EXPERIENCE:
    //   return <ExperienceForm id={action.content} />;
    // case ModalTypeE.EXPERIENCE_OVERVIEW:
    //   return <ExperienceOverview experience={action.content} />;

    default:
      return <></>;
  }
};

export const ModalContext = React.createContext<ModalContextType>(
  {} as ModalContextType
);

export const ModalProvider: FunctionComponent = ({ children }) => {
  const { theme } = useTheme();

  const [isOpen, setIsOpen] = React.useState(false);
  const [state, setState] = React.useState({
    type: '',
    content: {},
    onClose: () => {},
    padding: '',
    size: '',
    showBar: true,
    overflow: false
  });

  const openModal = (action: {
    type: string;
    content?: any;
    padding?: any;
    size?: any;
    showBar?: any;
    overflow?: any;
    onClose?: any;
  }) => {
    setIsOpen(true);
    setState({
      type: action.type,
      content: action.content,
      onClose: action.onClose,
      padding: action.padding && action.padding,
      size: action.size && action.size,
      showBar: action.showBar,
      overflow: action.overflow,
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const afterClose = () => {
    const { onClose } = state;
    onClose();
  };

  return (
    <ModalContext.Provider
      value={{ isOpen, openModal, closeModal, afterClose }}
    >
      {children}
      <ModalComponent
        initialState={isOpen}
        closeModal={closeModal}
        theme={theme}
        padding={state.padding}
        size={state.size}
        showBar={state.showBar}
        overflow={state.overflow}
      >
        {modalAssert(state)}
      </ModalComponent>
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  return React.useContext(ModalContext);
};
