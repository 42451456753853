import * as React from 'react';
import { Command as CommandComponent } from 'wally-design-system';

type CommandContextType = {
  isOpen: boolean;
  openCommand: () => void;
  closeCommand: () => void;
};

export const CommandContext = React.createContext<CommandContextType>(
  {} as CommandContextType
);

export const CommandProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openCommand = () => setIsOpen(true);
  const closeCommand = () => setIsOpen(false);

  return (
    <CommandContext.Provider
      value={{ isOpen, openCommand, closeCommand }}
    >
      {children}
      <CommandComponent
        initialState={isOpen}
        closeCommand={closeCommand}
       />
    </CommandContext.Provider>
  );
};

export const useCommand = (): CommandContextType => {
  return React.useContext(CommandContext);
};
