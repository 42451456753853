import React from 'react';
import { useModal, useNotification } from '~/context';
import { Button, Form } from 'wally-design-system'
import { useFetcher } from 'remix';

type EventSettingsProps = {
  event: any;
};

export const EventSettings: React.FC<EventSettingsProps> = ({ event }) => {
  const { openModal, closeModal } = useModal();
  const { openNotification } = useNotification()
  const fetcher = useFetcher();

  React.useEffect(() => {
    if (fetcher.type === 'done') {
      const { status, message } = fetcher.data
      closeModal();
      openNotification({ title: message, status });
    }
  }, [fetcher])


  return (
    <div>
      <Form
        type="fetcher"
        component={fetcher.Form}
        fields={[
          { field: {
            type: 'text',
            id: 'availabilities_id',
            hidden: true,
            defaultValue: event.availabilities_id,
            required: true,
          }},
        ]}
        action='/availabilities/delete'
        button={{ text: 'Delete', variant: 'secondary' }}
      />
    </div>
  );
};
